<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin w-sm-475px">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Forgot Password?</h3>
        <p class="text-muted font-weight-semi-bold">Enter your email</p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          role="alert"
          v-bind:class="{
            show: alerts.length,
            'alert-danger': alert.type == 'danger',
            'alert-success': alert.type == 'success',
          }"
          v-for="(alert, i) in alerts"
          :key="i"
          class="alert fade"
        >
          <div class="alert-text">{{ alert.title }}</div>
        </div>

        <b-form-group label-for="f-p-email">
          <template v-slot:label>
            Email
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            class="form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
            id="f-p-email"
            name="f-p-email"
            v-model="$v.form.email.$model"
            :state="validateState($v.form.email)"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
          <router-link to="/login" tag="a" class="text-dark-60 text-hover-primary my-3 mr-2">
            Sign In!
          </router-link>
          <button v-cs-loading="submiting" class="ml-auto btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3">
            Submit
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<script>
import { PASSWORD_FORGOT } from '@/store/modules/auth';
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'forgot-password',
  data() {
    return {
      form: { email: '' },
      submiting: false,
      alerts: [],
    };
  },
  validations: {
    form: {
      email: { required, email },
    },
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      const params = this.jsonToFormData({ admin_user: this.form });

      this.submiting = true;

      this.$store
        .dispatch(PASSWORD_FORGOT, params)
        .then(() => {
          this.alerts = [{ title: 'We have e-mailed your password reset link!', type: 'success' }];
          this.form.email = '';
          this.$v.form.$reset();
        })
        .catch((err) => {
          this.alerts = [{ title: err.data.result, type: 'danger' }];
        })
        .finally(() => (this.submiting = false));
    },
  },
};
</script>
